<template>
    <section class="content">
    <div class="flex justify-content-center">
    <div class="w-100 card">
    <div class="card-body">
        <div class="row">
        <div class="col-xl-12 col-md-12 flex">
            <div class="row" v-if="false">
                <div class="col-auto">
                <h4>Periode Laporan</h4>
                </div>
                <div class="col-md-3">
                <div class="input-group mb-3">
                    <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                    <div class="input-group-append">
                    <div
                        class="input-group-text"
                        style="background-color: #fff">
                        <span><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
        <div class="mb-2"></div>
        <div class="table-responsive">
            <table style="width:100%" class="table table-hover" ref="tbljurnal">
            <thead>
                <tr>
                <th>NO. JURNAL</th>
                <th>TANGGAL</th>
                <th>REFERENSI</th>
                <th>INVOICE ID</th>
                <th>NO. AKUN</th>
                <th>NAMA AKUN</th>
                <th>DEBIT</th>
                <th>KREDIT</th>
                <th>NOTE</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            <tfoot>
            <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            </tr>
            </tfoot>
            </table>
        </div>
    </div>
    </div>
    </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "Jurnalentri",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
            },
            roles: "",
            role_id: "",
            coa_id: "",
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
        this.dateEnd = moment().format("YYYY-MM-DD");
    },
    methods: {
        onPeriode() {
        this.tbl.api().ajax.reload();
        },
        getDataItem(aData)
        {
            return {
                coa_id: aData.coa_id,
                coa_no: aData.coa_no,
                coa_name: aData.coa_name,
            }
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
            }
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment().add(-1, 'M').startOf("month"),
        endDate: moment(),
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.onPeriode();
        });

    let journalNo = '';
    let journalColor = '';
    this.tbl = createTable(e.tbljurnal, {
        title: "",
        roles: this.$route.params.roles,
        selectedRowClass: "",
        serverSide: true,
        sort: false,
        frame: false,
        processing: true,
        lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        displayLength: 10,
        lengthChange: true,
        ajax: "/report/jurnal_entri",
        columnDefs: [
            {
                targets: 0,
                createdCell: function (td, cellData, rowData, row, col) {
                    if (rowData.group_id > 0) {
                        $(td)
                        .css('position', "relative");

                        $(td)
                        .find(".triangle")
                        .css('width', 10)
                        .css('height', 10)
                        .css('border-bottom', "10px solid rgb(16, 50, 82)")
                        .css('border-right', "10px solid transparent")
                        .css('margin-left', "-0.3rem")
                        .css('bottom', 0)
                        .css('position', "absolute");
                    }
                }
            },
            { targets: 2, width: "135px" },
        ],
        paramData: function (d) {
            // d.dt1 = self.dateStart;
            // d.dt2 = self.dateEnd;
        },
        columns: [
            { data: "journal_no",
            render: function(data, type, row, meta) {
                return `${data}<div class="triangle"></div>`;
            } },
            { data: "journal_date",
            render: function(data, type, row, meta) {
                return moment(data).format("DD/MM/YYYY");
            } },
            { data: "referensi" },
            { data: "invoice_code",
            render: function(data, type, row, meta) {
                return !data ? '' : data;
            } },
            { data: "coa_no" },
            { data: "coa_name" },
            { 
            data: "debit", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
            { 
            data: "credit", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
            { data: "note" },
        ],
        filterBy: [0,2,3,4,5,8],
        rowCallback: function (row, data, displayNum, displayIndex, dataIndex) {
            if (journalNo == '') {
                journalColor = 'white';
                $(row).css('background-color', 'white');
            } else {
                if (data.journal_no != journalNo) {
                    if (journalColor == 'white') {
                        journalColor = '#CDD0CB';
                        $(row).css('background-color', '#CDD0CB');
                    } else {
                        $(row).css('background-color', 'white');
                        journalColor = 'white';
                    }
                } else {
                    if (journalColor == 'white') {
                        $(row).css('background-color', 'white');
                    } else {
                        $(row).css('background-color', '#CDD0CB');
                    }
                }
            }
            journalNo = data.journal_no;
        },
        initComplete: function () {
            $('.loading-overlay').removeClass('show');
        },
        "createdRow": function( row, data, dataIndex ) {
            if ( data[3] != '') {
                $(row).addClass( 'text-right' );
            }
            // console.log(row);
        }
        });
    },
}
</script>